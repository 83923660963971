
const AppFooter = () => {
    return (
      <div className='footerPanel'>
        <div id="footerBanner" style={{minHeight: 75}}>
   
        </div>

        <div className="mainFooterContainer">
        <p style={{margin: 10}}>ver 0.15</p>
        </div>

      </div>
  )
  }
  
  export default AppFooter;