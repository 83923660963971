import React, { useEffect, useState } from "react";
import { Checkbox } from "primereact/checkbox";
import { InputTextarea } from "primereact/inputtextarea";
import { Facility } from "../../services/form-controller.service";

export interface ControlSixProps {
  label: string;
  fieldName: string;
  textareaFieldName: string;
  _globalFieldChangeHandler: (e: any) => void;
  _currentFormData?: any;
  _facility?: Facility;
  _costCallback: any;
  _disabled: boolean;
}

const ControlSix: React.FC<ControlSixProps> = ({
  label,
  fieldName,
  textareaFieldName,
  _globalFieldChangeHandler,
  _currentFormData,
  _costCallback,
  _facility,
  _disabled
}) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [_data, setData] = useState<any>({});

  const handleCheckboxChange = (e: any) => {
    setIsEnabled(e.target.checked);

    if (!e.target.checked) {
      setData({ ..._data, [textareaFieldName]: "" });
      _costCallback({
        selected: false,
        name: _facility?.facility,
        category: _facility?.category,
        selectedOption: _data[textareaFieldName],
        valid: false
          })

          _globalFieldChangeHandler({
            target: {
              id: fieldName,
              value: false
            }
          });

          _globalFieldChangeHandler({
            target: {
              id: textareaFieldName,
              value: ""
            }
          });
    } else {

      _costCallback({
        selected: e.target.checked,
        name: _facility?.facility,
        category: _facility?.category,
        valid: false
          })
          _globalFieldChangeHandler(e);
    }

  };

  const _handleChange = (e: any) => {
    setData({ ..._data, [e.target.id]: e.target.value });

    _costCallback({
      selected: isEnabled,
      name: _facility?.facility,
      category: _facility?.category,
      selectedOption: _data[textareaFieldName],
      valid: true
    })
    _globalFieldChangeHandler(e);
  };

  useEffect(() => {
    if (_currentFormData) {
      setData(_currentFormData);
      if(_currentFormData[textareaFieldName]){
        setIsEnabled(true);
      }

    }

  }, []);

  return (
    <div>


      <div style={{ display: "flex", marginBottom: "1rem" }}>
        <div style={{ width: "40%" }}>
        <Checkbox
            id={`${fieldName}`}
            onChange={handleCheckboxChange}
            checked={isEnabled}
            disabled={ _disabled}
          /> &nbsp;
          <label htmlFor={textareaFieldName}>{label}</label>
        </div>
        <div style={{ width: "40%" }}>
          <InputTextarea
            id={textareaFieldName}
            rows={5}
            cols={30}
            onChange={_handleChange}
            value={_data[textareaFieldName]}
            disabled={!isEnabled || _disabled}
          />
        </div>
      </div>
    </div>
  );
};

export default ControlSix;
