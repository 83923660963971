// ContactTable.tsx
import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Card } from 'primereact/card';

interface ContactInfo {
    location: string;
    contactName: string;
    phoneNumber: string;
    email: string;
}

const contactData: ContactInfo[] = [
    { location: "'s-Gravenzande", contactName: "André van Rossen", phoneNumber: "06-14547866", email: "begraafplaatssgravenzande@gemeentewestland.nl" },
    { location: "Maasdijk", contactName: "André van Rossen", phoneNumber: "06-14547866", email: "begraafplaatsmaasdijk@gemeentewestland.nl" },
    { location: "Monster", contactName: "André van Rossen", phoneNumber: "06-14547866", email: "begraafplaatsmonster@gemeentewestland.nl" },
    { location: "Naaldwijk", contactName: "Gilbert Vlaming", phoneNumber: "06-42529867", email: "begraafplaatsnaaldwijk@gemeentewestland.nl" },
    { location: "Wateringen", contactName: "Gilbert Vlaming", phoneNumber: "06-42529867", email: "begraafplaatswateringen@gemeentewestland.nl" },
    { location: "De Lier", contactName: "Gilbert Vlaming", phoneNumber: "06-42529867", email: "begraafplaatsdelier@gemeentewestland.nl" }
];

const ContactTable: React.FC = () => {
    return (
        <Card title="Cemetery Contacts" style={{ margin: '2rem', paddingTop: 100 }}>
            <DataTable value={contactData}  rows={10} tableStyle={{ minWidth: '50rem' }}>
                <Column field="location" header="Location" style={{ width: '25%' }}></Column>
                <Column field="contactName" header="Contact Name" style={{ width: '25%' }}></Column>
                <Column field="phoneNumber" header="Phone Number" style={{ width: '25%' }}></Column>
                <Column field="email" header="Email" style={{ width: '25%' }}></Column>
            </DataTable>
        </Card>
    );
};

export default ContactTable;
