import React, { useEffect, useState } from "react";
import { Dropdown } from "primereact/dropdown";
import { Calendar } from "primereact/calendar";
import { Panel } from "primereact/panel";
import { Checkbox } from "primereact/checkbox";
import { Facility } from "../../services/form-controller.service";
import { Message } from "primereact/message";
import { Chip } from "primereact/chip";
import { InputMask } from "primereact/inputmask";

interface DropdownOption {
  label: string;
  value: any;
}

export interface ControlTwoProps {
  label: string;
  fieldName: string;
  fromDateFieldName: string;
  toDateFieldName: string;
  _globalFieldChangeHandler: (e: any) => void;
  _currentFormData?: any;
  _facility?: Facility;
  _costCallback: any
  _disabled: boolean
}

const ControlTwo: React.FC<ControlTwoProps> = ({
  fieldName,
  label,
  fromDateFieldName,
  toDateFieldName,
  _globalFieldChangeHandler,
  _currentFormData,
  _facility,
  _costCallback,
  _disabled
}) => {
  const [isEnabled, setIsEnabled] = useState(false);
  const [_data, setData] = useState<any>({});
  const [_priceUnit, setPriceUnit] = useState<any>("");
  const [_message, setMessage] = useState<any>("");
  const [messageType, setMessageType] = useState<"error" | "info" | "warn">(
    "info"
  );
  const [_max, setMax] = useState<any>(0);
  const [_price, setPrice] = useState<any>(0);
  const [_priceTotal, setPriceTotal] = useState<any>("€ 0,00");

  const handleCheckboxChange = (e: any) => {
    setIsEnabled(e.target.checked);
    if(e.target.checked){
      _costCallback({
        selected: true,
        name: _facility?.facility,
        category: _facility?.category,
        valid: false
      })
      _globalFieldChangeHandler(e);

    }
    if(!e.target.checked){
      setPriceTotal('€ 0,00');
      setData({ ..._data, 
        [fromDateFieldName]: '',
        [toDateFieldName]: ''
      });
      _costCallback({
        selected: false,
        name: _facility?.facility,
        category: _facility?.category,
        valid: false
      })
      _globalFieldChangeHandler({
        target: {
          id: fieldName,
          value: false
        }
      });
      _globalFieldChangeHandler({
        target: {
          id: fromDateFieldName,
          value: ''
        }
      });

      _globalFieldChangeHandler({
        target: {
          id: toDateFieldName,
          value: ''
        }
      });

    } 

  };

  const _handleChange = (e: any) => {
    setData({ ..._data, [e.target.id]: e.target.value });
    _globalFieldChangeHandler(e);
  };

  useEffect(() => {
    if (
      _currentFormData[fromDateFieldName] &&
      _currentFormData[toDateFieldName]
    ) {
      setIsEnabled(_currentFormData[fieldName]);
      setData(_currentFormData);
    }
   
  }, []);

  useEffect(() => {
    _calculateCosts();
  }, [_data]);

  const  calculateDaysBetweenDates = (startDate: Date, endDate: Date): number => {
    // Calculate the difference in time (milliseconds) between the two dates
    const timeDifference = endDate.getTime() - startDate.getTime();

    // Convert the time difference from milliseconds to days
    const daysDifference = timeDifference / (1000 * 3600 * 24);

    // Return the absolute value of the difference in days
    return Math.abs(daysDifference);
  }

  function parseDateFromEUString(input: string) {

    const parts = input.split("-");
    return new Date(Number(parts[2]), Number(parts[1]) - 1, Number(parts[0]));
  }
  const _calculateCosts = () => {
    const _relatedFacility = _facility
    if (isEnabled && _data[fromDateFieldName] && _data[toDateFieldName]) {
      const _fromDate = parseDateFromEUString(_data[fromDateFieldName]);
      const _toDate = parseDateFromEUString(_data[toDateFieldName]);

      if(isNaN(_fromDate.getTime()) || isNaN(_toDate.getTime())){
        return;
      }
      if(_relatedFacility?.hasOwnProperty('max')){
        setMax(_relatedFacility?.max)
      } else {
        setMax(99999999)
      }
      
      let daysTotal = 0;
      if(_fromDate && _toDate){
         daysTotal = calculateDaysBetweenDates(_fromDate, _toDate);
      if(daysTotal > (_relatedFacility?.max as any)){
        setMessageType('error')
        setMessage('Max days Exceeded')
      } else {
        setMessageType('info')
        setMessage('')
      }
      }
      setPriceTotal(`€ ${_facility?.price},00`);

      _costCallback({
        selected: true,
        name: _relatedFacility?.facility,
        category: _relatedFacility?.category,
        selectedOption: '',
        relatedDates: { from: _data[fromDateFieldName], to: _data[toDateFieldName]},
        totalCost: Number(_facility?.price),
        valid: true
      })
    } else {
      console.log('isEnable == false')
      _costCallback({
        selected: false,
        name: _relatedFacility?.facility,
        category: _relatedFacility?.category,
        valid: false
      })
      setPriceTotal(`€ 0,00`);
      
    }
  };




  const renderHeader = () => {
    return (
      <>
        <Checkbox
        disabled={_disabled}
          checked={isEnabled}
          id={`${fieldName}`}
          onChange={handleCheckboxChange}
          style={{ marginRight: "1rem" }}
        />
        &nbsp;&nbsp;{label}&nbsp;&nbsp;
        {_message ? <Message severity={messageType} text={_message} /> : null}
      </>
    );
  };
  return (
    <Panel header={renderHeader()}>
      <div
        style={{ display: "flex", marginBottom: "1rem" }}
      >
        <div style={{ display: "flex", width: "40%" }}>
          <div style={{ width: "100%", display: "flex" }}>
            <label htmlFor="enableControls" className="dropdown-label">
              {label}:
            </label>
          </div>
        </div>
        <div style={{ width: "40%" }}></div>
        <div style={{ width: "20%" }}>
          <h5>{_priceTotal}</h5>
        </div>
      </div>
      <div style={{ display: "flex", marginBottom: "1rem" }}>
        <div style={{ width: "40%" }}>
          <label htmlFor={fromDateFieldName}>Datum van:</label>
        </div>
        <div style={{ width: "40%" }}>
          <InputMask
            mask="99-99-9999"
            id={fromDateFieldName}
            onChange={_handleChange}
            value={_data[fromDateFieldName]}
            disabled={!isEnabled || _disabled}
          />
        </div>
      </div>
      <div style={{ display: "flex", marginBottom: "1rem" }}>
        <div style={{ width: "40%" }}>
          <label htmlFor={toDateFieldName}>Datum tot:</label>
        </div>
        <div style={{ width: "40%" }}>
          <InputMask
            mask="99-99-9999"
            id={toDateFieldName}
            onChange={_handleChange}
            value={_data[toDateFieldName]}
            disabled={!isEnabled || _disabled}
          />
        </div>
      </div>
    </Panel>
  );
};

export default ControlTwo;
